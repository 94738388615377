<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" lg="6" xl="5">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <h1 class="title">
              Sign in to Your Silent Auction Vacations Dashboard
            </h1>
            <p class="body-1 mt-4">
              Enter the primary email used to register your package below.
            </p>
            <p class="body-1">
              Your primary email can be found in the email you received from us
              after registering your package.
            </p>
            <p class="font-italic">
              Please note that you cannot sign in with a secondary email.
            </p>
          </v-card-text>
          <v-card-text>
            <v-form ref="form" @submit.prevent="onSubmit">
              <!-- <v-text-field
                v-model.trim="email"
                outlined
                label="Primary Email *"
                :loading="loading"
                :disabled="loading"
                :rules="[textRules.required, emailRules.valid]"
                @input="lowercase"
              ></v-text-field> -->
              <v-text-field
                v-model.trim="email"
                label="Primary Email Address *"
                placeholder="name@gmail.com"
                filled
                :loading="loading"
                :disabled="loading"
                :rules="[textRules.required, emailRules.valid]"
                @input="lowercase"
              ></v-text-field>
            </v-form>
            <!-- user account not found -->
            <v-alert
              outlined
              type="error"
              icon="mdi-alert-outline"
              v-if="userAccountNotFound"
            >
              We were unable to find an account with that primary email address
              within our system. Please ensure you've entered your email
              correctly.<br />
              If you need help,
              <a href="mailto:info@silentauctionvacations.com?subject=ATTN: Unable to Log Into Traveler Dashboard">please click here to contact us.</a>
              <!-- <router-link to="/contact" class="font-weight-medium"
                >please click here to contact us.
              </router-link> -->
            </v-alert>
            <!-- user account not found -->
            <!-- access not allowed -->
            <v-alert
              outlined
              type="error"
              icon="mdi-alert-outline"
              v-if="accessNotAllowed"
            >
              We're having some trouble locating your package. <br />
              <router-link to="/contact" class="font-weight-medium"
                >Please click here to contact us.</router-link
              ><br />
              We apologize for any inconvenience.
            </v-alert>
            <!-- access not allowed -->
          </v-card-text>

          <v-card-actions>
            <v-btn
              outlined
              color="primary"
              @click="$router.push('/')"
              :loading="loading"
              :disabled="loading"
              >Back Home</v-btn
            >
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              @click="onSubmit"
              :loading="loading"
              :disabled="loading"
              >Sign In</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formRules from "@/mixins/formRules";
import firebase from "@/plugins/firebase";
import axios from "axios";
import moment from "moment";
export default {
  mixins: [formRules],
  data: () => ({
    email: "",
    userAccountNotFound: false,
    accessNotAllowed: false,
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    lowercase(e) {
      if (!e) return;
      this.email = e.toLowerCase();
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", true);

        // checking if email already used to create auth account
        let signInMethods = await firebase
          .auth()
          .fetchSignInMethodsForEmail(this.email);

        // auth account exists
        if (!signInMethods.length) {
          this.userAccountNotFound = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        // check for email in userAccounts collection
        let userAccountsRef = firebase
          .firestore()
          .collection("userAccounts")
          .where("email", "==", this.email);
        let userAccountFound = await userAccountsRef.get();

        // if no user accounts found with that email
        if (!userAccountFound.docs.length) {
          this.userAccountNotFound = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        
        // isolating specific data object assigned to email
        if(userAccountFound.docs.length == 1){
          userAccountFound = userAccountFound.docs
          .map((i) => i.data())
          .filter((i) => i.email == this.email )[0];
        }else{
          userAccountFound = userAccountFound.docs
          .map((i) => i.data())
          .filter((i) => i.email == this.email && i.id.includes(`+${userAccountFound.docs.length - 1}`))[0];
        }

        console.log("user",userAccountFound.id)
        
        

        // if doc canAccess = false
        if (!userAccountFound.canAccess) {
          this.accessNotAllowed = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        // sign in firestore auth user
        await firebase
          .auth()
          .signInWithEmailAndPassword(
            this.email,
            "$6Rp94^1Q*riax9!l*0R1#tnYr&a2BXPNXh@Szab7UBE$tomIuMaO*bVsa!fHNd%"
          );

        // vuex userID auto sets (main.js)

        if (userAccountFound.id !== "zSu84kS6DZbcZCkRWCX4ryCxqjI3") {
          // record user ip address for userAccount signIns array
          try {
            let ipRequest = await axios.get("https://api.ipify.org");
            let ipAddress = ipRequest?.data;
            
            console.log("ipRequest",ipRequest);

            let docRef = firebase
              .firestore()
              .collection("userAccounts")
              .doc(userAccountFound.id);

            if (!ipAddress) {
              // if !ipAddress update lastSignedIn as usual
              await docRef.update({
                lastSignedIn: new Date().toISOString().substring(0, 10),
              });
            } else {
              // if ipAddress => add to userAccount signIns array
              let unixMsTimestamp = moment(new Date()).format("x");
              let signInId = `${ipAddress.replaceAll(
                ".",
                ""
              )}-${unixMsTimestamp}`;

              await docRef.update({
                lastSignedIn: new Date().toISOString().substring(0, 10),
                signIns: firebase.firestore.FieldValue.arrayUnion({
                  date: new Date().toISOString().substring(0, 10),
                  id: signInId,
                  ipAddress: ipAddress,
                }),
              });
            }
          } catch (error) {
            console.log("error getting ip address", error);
          }
        }

        // handle success
        this.$toast.open({
          message: "Sign In Successful",
          type: "success",
        });

        await this.$store.dispatch("setLoading", false);
        this.$router.push("/dashboard");
        return;
      }
    },
  },
};
</script>
